import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

const query = graphql`
  query Payment {
    site {
      siteMetadata {
        url
        site
      }
    }
  }
`;

const PaymentsPage = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  return (
    <Layout>
      <Seo title="Τρόποι πληρωμής" />
      <Container style={{ paddingBottom: '4rem' }}>
        <TextLayoutContainer>
          <h1>Τρόποι πληρωμής</h1>
          <p>
            Για τη διευκόλυνση και εξυπηρέτηση όλων όσων επιθυμούν να αγοράσουν
            από το ηλεκτρονικό μας κατάστημα, το{' '}
            <Link to={info.url}>{info.site}</Link> διαθέτει τους ακόλουθους
            εναλλακτικούς τρόπους πληρωμής:
          </p>
          <h2>ΕΛΛΑΔΑ</h2>
          <ol>
            <li>
              Με τη χρέωση της πιστωτικής σας κάρτας. Μέσω πιστωτικών,
              χρεωστικών και προπληρωμένων καρτών των Visa, Mastercard, Maestro,
              American Express, Diners. Η χρέωση της πιστωτικής σας κάρτας
              γίνεται με την επιτυχή καταχώριση της παραγγελίας σας.
            </li>
          </ol>
          <p>
            Είναι σημαντικό να αναφέρετε απαραιτήτως ένα τηλέφωνο επικοινωνίας
            και μια έγκυρη διεύθυνση e-mail.
          </p>
          <p>
            Σε περίπτωση που η πιστωτική σας κάρτα δεν εγκριθεί από την τράπεζα
            σας, η εταιρεία ακυρώνει την παραγγελία σας.
          </p>
          <ol start="2">
            <li>
              Με αντικαταβολή τη στιγμή που θα παραλάβετε τα προϊόντα. Στην
              περίπτωση αυτή ο πελάτης θα επιβαρύνεται με χρέωση 2,50 ευρώ
              (συμπεριλαμβάνεται ΦΠΑ 24%).
            </li>
          </ol>
        </TextLayoutContainer>
      </Container>
    </Layout>
  );
};

export default PaymentsPage;
